@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

:root {
  --white: #ffffff;
  --pebble: #111827;
  --flint: #5f5d7e;
  --orange: #d97706;
  --beige: #fffbeb;
  --silver: #9d9cb6;
  --apricose: #fff1e6;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--white);
  color: var(--flint);
  font-family: 'Inter', sans-serif;
}

h1 {
  @apply my-4 text-2xl font-bold;
}

.content p {
  @apply my-4 font-light;
}

.main h1 {
  @apply my-4 text-3xl lg:text-4xl font-bold;
  line-height: 1.5rem;
  font-family: 'Abril Fatface', cursive;
  color: var(--orange);
  font-weight: 300;
}

.rmBase__compError:empty {
  @apply hidden;
}

.rmBase__compError {
  @apply p-2 my-1 text-white bg-red-400 rounded-lg sm:my-2;
}
